.ways-to-book-page {
  .section-title {
    font-size: 20px;
  }

  .card-row {
    display: flex;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }
  }

  .card {
    padding: 15px;
  }

  .card-row .card {
    flex: 1;
  }

  .card-row .card:not(:first-child) {
    margin-left: 20px;

    @media screen and (max-width: $mobile) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .allergy-reminder-subtitle span {
    margin-left: 5px;
  }

  .allergy-reminder-link {
    display: flex;
    text-decoration: none;
  }

  .allergy-reminder-link .card-header {
    padding: 6px 16px;
  }

  .allergy-reminder-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $text-color-primary-bg;
  }

  .prescription-card {
    margin-top: 40px;
  }

  .message-dr-title {
    margin-bottom: 40px;
  }

  .call-to-book-link {
    text-decoration: none;
  }

  .call-to-book-phone-number {
    padding-bottom: 0;
    font-size: 1.375rem;
    font-weight: 500;
    color: $text-color-primary-bg;

    @media all and (min-width: $mobile) and (max-width: 620px) {
      font-size: 1rem;
    }
  }

  .call-to-book-desktop-card .card-footer p {
    font-weight: 400;
    font-size: 12px;
  }

  .call-to-book-desktop-card {
    display: none;
  }

  .allergy-reminder-heeader-wrapper {
    display: flex;
    align-items: center;
  }

  .allergy-reminder-title {
    display: flex;
    align-items: center;
    flex: 1;
    color: $text-color-primary-bg;

    @media screen and (max-width: 620px) {
      display: block;
    }
  }

  .allergy-reminder-subtitle {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    flex: 1;

    @media screen and (max-width: 620px) {
      text-align: left;
      margin-top: 5px;
    }
  }

  .allergy-reminder-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $text-color-primary-bg;
  }

  .cards-bottom-wrapper .card {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .ways-to-book-page {
    .call-to-book-desktop-card {
      display: block;
    }

    .call-to-book-mobile-card {
      display: none;
    }

    .cards-bottom-wrapper {
      display: flex;
    }

    .prescription-card {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .ways-to-book-page {
    .call-to-book-phone-number {
      font-size: 16px;
    }

    .call-to-book-phone {
      display: none;
    }
  }
}

@media screen and (max-width: 639px) {
  .ways-to-book-page {
    .allergy-reminder-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
