@use 'sass:math';
@import '../../../../styles/ui/breakpoints.scss';
@import '../../../../styles/ui/colors.scss';
@import '../../../../styles/ui/spacing.scss';
@import '../../../../styles/ui/typography.scss';

@import '../../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.hsfConsultation {
  background-color: $color-white;
  height: 270px;
  min-height: 270px;
  margin: 10px 15px 10px 5px;
  padding: $baseline * 1.5;
  flex-basis: 50%;
  text-decoration: none;
  display: block;

  @media screen and (min-width: $tablet) {
    width: calc(50% - 20px);
    display: inline-block;
    height: 240px;
    min-height: 240px;
  }

  .wrapper {
    display: grid;
    grid-template-rows: 45px auto 1fr;
    height: 100%;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $baseline;

    .serviceIcon {
      background-color: $color-icon;
      flex-shrink: 0;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      font-size: var(--font-size-xl);
      font-weight: 700;
      margin-left: $baseline;
      color: $text-color-primary-bg;

      @media screen and (min-width: ($laptop - 1)) {
        font-size: var(--font-size-m);
      }
    }
  }

  .content {
    p {
      font-size: var(--font-size-s);
      line-height: 1.28;
      color: $text-color-secondary-bg;
    }
  }

  .icons {
    display: flex;
    background-color: #f0f2f7;
    margin-top: auto;
    padding: $baseline;
    justify-content: center;
    font-size: var(--font-size-xl);
    font-weight: 700;
    color: $text-color-secondary-bg;
  }
}
