.my-contacts-page {
  .edit-contact-nav-item {
    text-decoration: none;
  }

  .contact-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $text-color-primary-bg;
  }

  .delete-contact-button {
    text-decoration: underline;
    color: $color-primary;
    border-color: transparent;
    background-color: transparent;
  }

  .contact-info-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .add-contact-button {
    text-decoration: none;
  }

  .contact-list .paper:not(:first-child) {
    margin-top: 10px;
  }
}
