// To be used in the future, either this way or with
// a base spacing unit of 4px
//
// $spacings: (
//   auto: auto,
//   0: 0,
//   1: 0.25rem,
//   2: 0.5rem,
//   3: 0.75rem,
//   4: 1rem,
//   5: 1.25rem,
//   6: 1.5rem,
//   7: 1.75rem,
//   8: 2rem,
//   9: 2.25rem,
//   12: 3rem,
//   14: 4rem,
//   20: 5rem,
// );

// V3 CSS vars
:root {
  --spacing-auto: auto;
  --spacing-0: 0;
  --spacing-1: 0.25rem; // 4px
  --spacing-2: 0.5rem; // 8px
  --spacing-3: 0.75rem; // 12px
  --spacing-4: 1rem; // 16px
  --spacing-5: 1.25rem; // 20px
  --spacing-6: 1.5rem; // 24px
  --spacing-7: 1.75rem; // 28px
  --spacing-8: 2rem; // 32px
  --spacing-9: 2.25rem; // 36px
  --spacing-10: 2.5rem; // 40px
  --spacing-12: 3rem; // 48px
  --spacing-14: 4rem; // 64px
  --spacing-20: 5rem; // 80px
}

// Going back to the old multipliers of 10px
$pad: 10px;

$spacings: (
  auto: auto,
  0: 0,
  1: $pad,
  2: $pad * 2,
  3: $pad * 3,
  4: $pad * 4,
  5: $pad * 5,
  6: 1.5rem,
  7: 1.75rem,
  8: 2rem,
  9: 2.25rem,
  12: 3rem,
  14: 4rem,
  20: 5rem,
);

@mixin generateSpacing($property, $alias) {
  @each $key, $value in $spacings {
    // All sides e.g. p-4, m-4
    .#{$alias}-#{$key} {
      #{$property}: $value;
    }

    // Horizontal and vertical e.g. px-4, my-4
    .#{$alias}x-#{$key} {
      #{$property}-left: $value;
      #{$property}-right: $value;
    }
    .#{$alias}y-#{$key} {
      #{$property}-top: $value;
      #{$property}-bottom: $value;
    }

    // Individual sides e.g. pl-4, mb-4
    .#{$alias}t-#{$key} {
      #{$property}-top: $value;
    }

    .#{$alias}l-#{$key} {
      #{$property}-left: $value;
    }

    .#{$alias}r-#{$key} {
      #{$property}-right: $value;
    }

    .#{$alias}b-#{$key} {
      #{$property}-bottom: $value;
    }
  }
}

@include generateSpacing(padding, p);
@include generateSpacing(margin, m);
