.medical-information-page {
  .medical-title {
    font-size: 18px;
    font-weight: 700;
  }

  .medical-navigate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    font-weight: 700;
  }

  .separator {
    height: 1px;
    width: 90%;
    align-self: center;
    margin: auto;
    background-color: #979797;
  }

  .medical-information-search .btn {
    padding: 0 10px;
    border-left: none;
  }

  .medical-information-search .form-control {
    flex: 1 1;
  }

  .medical-information-search .text-input-wrapper {
    border-right: none;
  }

  .medical-information-search-title {
    font-size: 18px;
    font-weight: 700;
  }

  .medical-information-search-input-wrapper {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) {
  .medical-information-page {
    .btn-container {
      margin: 0 auto;
      width: 280px;
    }
  }
}
