.book-single-phone-consultation-page {
  .book-single-phone-consultation-section.Counsellor {
    background-image: url('../../images/emotionalWellbeing.jpg');
  }

  .book-single-phone-consultation-section.Physiotherapist {
    background-image: url('../../images/achesAndPains.jpg');
  }

  .book-single-phone-consultation-section.SecondOpinion {
    background-image: url('../../images/secondOpinion-mobile.jpg');
  }
}
