@import '../functions.scss';

$radio-checkmark-color: '#fff';

$checkmark: '<?xml version="1.0" encoding="UTF-8"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path fill="#{$radio-checkmark-color}" d="M6.063,9.884 C5.987,9.958 5.883,10 5.775,10 C5.666,10 5.562,9.958 5.486,9.884 L3.18,7.657 C2.94,7.425 2.94,7.05 3.18,6.819 L3.468,6.54 C3.708,6.309 4.095,6.309 4.335,6.54 L5.775,7.931 L9.665,4.174 C9.905,3.942 10.293,3.942 10.532,4.174 L10.82,4.452 C11.06,4.684 11.06,5.059 10.82,5.289 L6.063,9.884 Z" fill-rule="nonzero"></path></svg>';

label {
  display: block;
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 0.75rem;
  padding: 4px 0;
  color: $text-color-primary-bg;
}

input,
select,
textarea {
  font-size: var(--font-size-m);
}

.text-input-wrapper,
.password-input-wrapper {
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  display: flex;
  background-color: $color-white;
  align-items: center;
}

.password-input-wrapper {
  .eye-icon {
    width: 20px;
    height: 17px;
  }

  .eye-open-icon {
    path.fg {
      stroke: $color-primary;
    }
  }
}

.text-input-error-icon {
  position: absolute;
  right: 4px;
  top: 10px;
}

input.password-input {
  width: 100%;
  appearance: none;
  border-width: 0;
  border: none;
  padding-left: 10px;
  height: 45px;
  margin-right: 4px;
}

.error-icon {
  width: 20px;
  height: 20px;

  path.bg {
    fill: $color-warning;
  }
}

.text-input-wrapper .error-icon {
  margin: 0 5px;
}

input.text-input {
  width: 100%;
  appearance: none;
  border-width: 0;
  border: none;
  padding-left: 10px;
  height: 40px;
}

.input-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.input-btn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}

.text-input-multi {
  border-radius: 10px;
  width: 100%;
  height: 80px;
  padding: 5px;
  border: 1px solid #e5e8f0;
}

textarea.text-area-input {
  width: 100%;
  appearance: none;
  border-width: 0;
  border: none;
  padding: $baseline * 0.5;
  height: 80px;
  resize: none;
}

.has-error,
.text-area-input.has-error {
  border: 1px solid var(--text-validation-error);
  border-radius: 0;
}

.native-select-container {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.native-select-container select {
  height: 40px;
  width: 100%;
  padding-left: 10px;
  background-color: $color-white;
  border: 1px solid #dfe2ed;
  border-radius: 0;
}

// to be used in template revamp
// .native-select-container select {
//   &:invalid {
//     color: $text-color-secondary-bg;
//   }
//   [disabled] {
//     color: $text-color-secondary-bg;
//   }
// }

.file-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .file-picker-label {
    font-size: var(--font-size-s);
  }

  label {
    border-radius: $border-radius-s;
    border: 1px solid #999;
    padding: $baseline * 0.5 $baseline;
    font-size: var(--font-size-m);
    cursor: pointer;
    white-space: nowrap;

    &:focus {
      outline-color: inital;
    }
  }

  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.btn.show-password {
  width: 40px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.btn.show-password .show-password-icon {
  width: 20px;
  height: 17px;
  max-width: inherit;
  background: transparent;
}

.password-input-wrapper {
  display: flex;
}

.form-control .error-message {
  margin-top: 2px;
  font-size: var(--font-size-xs);
  color: var(--text-validation-error);
  font-weight: 400;
}

.form-control label {
  display: flex;
  align-items: center;
}

input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

.radio-button {
  display: inline-block;
}

.radio-button input {
  opacity: 0;
  position: absolute;
}

.radio-button-control {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-color: inherit;
  border: 1px solid $text-color-primary-bg;
  border-radius: 50%;
}

.radio-button input:checked + .radio-button-control:after {
  content: inline-svg($checkmark);
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 14px;
  height: 14px;
  background-color: $color-primary;
  border-radius: 50%;
}

.radio-button input:focus + .radio-button-control {
  box-shadow: 0 0px 8px $color-primary;
}

.radio-button input:checked + .radio-button-control {
  border-color: $color-primary;
  background-color: $color-primary;
}

.custom-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: var(--font-family-primary);
    font-weight: 500;
  }

  & + label.text-weight-md {
    font-weight: 400;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: flex;
    vertical-align: text-top;
    width: 14px;
    min-width: 14px;
    height: 14px;
    background: white;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  // Box hover
  &:hover + label:before {
    border: 1px solid $color-primary;
  }

  // Box focus
  // &:focus + label:before {
  //   box-shadow: 0 0 0 3px $color-primary;
  // }

  // Box checked
  &:checked + label:before {
    background: $color-white;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 14px;
    background: $color-primary;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $color-primary, 4px 0 0 $color-primary,
      4px -2px 0 $color-primary, 4px -4px 0 $color-primary,
      4px -6px 0 $color-primary, 4px -8px 0 $color-primary;
    transform: rotate(45deg);
  }
}

.form-control {
  .radio-control {
    height: 41px;
    cursor: pointer;

    .paper-header {
      font-weight: 500;
      color: $text-color-primary-bg;
      font-size: var(--font-size-m);
    }
  }
}

.form-control .radio-container {
  height: 40px;
}

.radio-container .paper-content {
  padding: 0px 10px;
  align-items: center;
  display: flex;
  flex: 1;
}

.radio-container .paper-content .paper-header {
  flex: 1;
  width: 100%;
}

.form-control .list {
  background-color: $color-white;
}

.form-control .list .list-item {
  padding: 0 0 0 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.form-control .list .list-item .list-item-text {
  display: flex;
  flex: 1;
  align-items: center;
}

.form-control .list-item .list-item-remove-icon {
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
}

.form-control .list-item:not(:last-child) {
  border-bottom: 1px solid #dfe2ed;
}

.text-input-wrapper .btn.btn-primary {
  border: none;
}

.custom-input-button {
  padding: 11px;
}

.list-input .btn.list-item-remove {
  border: none;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .custom-checkbox {
    &:checked + label:after {
      top: 8px;
    }
  }
}
