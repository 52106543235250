.confirm-modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba(36, 45, 56, 0.5);
}

.confirm-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 92%;
  max-width: 600px;
  padding: 32px;
  background-color: $color-white;
  border-radius: 12px;
  overflow-y: auto;

  .modal-title {
    text-align: center;
    font-weight: 700;
    font-size: 1.25rem;
    max-width: 80%;
    margin: 0 auto;

    @media screen and (min-width: $tablet) {
      font-size: 1.25rem;
    }

    .error-icon {
      width: 33.6px;
      height: 33.6px;
      margin-bottom: 12px;

      @media screen and (min-width: $tablet) {
        width: 48px;
        height: 48px;
        margin-bottom: 24px;
      }
    }
  }
}

.confirm-modal-content {
  color: $text-color-primary-bg;

  .modal-text {
    margin-top: 24px;
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.35;
    text-align: center;
  }

  .modal-button {
    flex: 1 1 auto;
    padding: 14px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .cancel-button {
    border: 1px solid #dfe3ed;
    color: $text-color-primary-bg;
    background-color: transparent;
  }

  .submit-button {
    margin-left: 20px;
    color: $color-white;
    background-color: $color-primary;
  }

  .confirm-modal-btn-container {
    display: flex;
    width: 100%;
  }
}

// App out of the date modal
.app-update-modal {
  .modal-text {
    p {
      margin-bottom: 20px;
    }
  }
  .confirm-modal-btn-container {
    button {
      width: 100%;
      max-width: 300px;
      margin: 28px auto 0;
      display: block;

      @media screen and (min-width: $tablet) {
        margin-top: 36px;
      }
    }
  }
}

// Confirm delete dependant
.my-contacts-page {
  .confirm-modal-wrapper {
    .confirm-modal {
      .confirm-modal-btn-container {
        margin-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 32px;

        @media screen and (min-width: $tablet) {
          margin-top: 36px;
        }

        .btn-primary {
          width: 50%;

          &.cancel-button {
            background-color: $color-white;
            color: $text-color-primary-bg;
            border-color: #e7e7e7;
          }
        }
      }
    }
  }
}