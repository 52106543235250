.change-password-page {
  .new-password-modal-content {
    padding: 0 24px;
  }
}

.new-password-modal {
  .page-header-wrapper {
    display: none;
  }
}

.new-password-modal-content {
  .requirement-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .requirement-icon {
    position: relative;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: $color-white;
    margin-right: 12px;
    border: 1px solid #e7e7e7;
  }

  .requirement-icon:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 32%;
    width: 3px;
    height: 5px;
    border: solid #e7e7e7;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .requirement-icon.checked {
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .requirement-icon-text {
    color: $text-color-primary-bg;
  }

  .error-text {
    color: var(--text-validation-error);
  }
}

@media screen and (min-width: $tablet) {
  .new-password-modal {
    .action-field-container {
      margin-left: auto;
      margin-right: auto;
    }

    .page-header-wrapper {
      display: flex;
    }

    .new-password-modal-header {
      display: none;
    }

    .requirement-handle-value {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-container {
      margin: 0 auto;
      width: 380px;
    }
  }
}
