.forgot-password-page {
  .page-content {
    width: 90%;
    max-width: 660px;
    margin: 0 auto;
    padding: 10vh 0 $baseline;

    form {
      max-width: 350px;
      margin: 0 auto;
    }
  }

  .btn {
    max-width: 280px;
  }
}
