.login-page {
  form {
    width: 90%;
    max-width: 350px;
    margin: 10vh auto $baseline;
  }

  .error-text {
    color: var(--text-validation-error);
  }
}
