// Session Timeout Banners: IdleBanner & AutoLogoutBanner
.session-timeout {
  .notification-item {
    border-left: 20px solid #ffb800;
    background: #4e74b7;
    padding: 20px;

    .notification-icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      path.mark {
        fill: #4e74b7;
      }
    }
  }

  .idle-banner-container {
    position: fixed;
    z-index: 4;

    .notification-item-wrapper {
      padding: 30px 30px 20px 30px;
    }

    .notification-item {
      .continue-btn-container {
        padding: 0px 20px 0px 24px;
      }

      button {
        font-size: var(--font-size-m);
      }
    }
  }

  .auto-logout-container {
    .notification-item {
      position: fixed;
      z-index: 1;
    }

    button {
      background: none;
      border: none;
      align-self: baseline;
      margin-left: 16px;
    }

    .close-icon {
      width: 20px;
      height: 20px;

      path {
        fill: white;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .idle-banner-container {
      .notification-item {
        flex-direction: row;

        .notification-title-container {
          margin-bottom: 0;

          p {
            margin-right: 12px;
          }
        }

        button {
          width: auto;
        }
      }
    }
  }

  @media screen and (min-width: 500px) {
    .idle-banner-container {
      .banner-content {
        justify-content: flex-end;
      }
    }
  }
}
