@import 'mixins.scss';

.hide-mobile {
  @include mobile {
    display: none;
  }
}

.hide-tablet {
  @include tablet {
    display: none;
  }
}

.hide-laptop {
  @include laptop {
    display: none;
  }
}

.hide-desktop {
  @include desktop {
    display: none;
  }
}

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex';

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-center {
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-row.space-between,
.flex-row-center.space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: space-between;
}

.flex-align-self {
  align-self: center;
}

.flex-row.column-text {
  flex-direction: column;
}

.flex-row.wrap {
  flex-wrap: wrap;
}

@each $display in $displays {
  .display-#{$display} {
    display: #{$display};
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.font-primary {
  font-family: var(--font-family-primary);
}

.font-secondary {
  font-family: var(--font-family-secondary);
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-full {
  width: 100%;
}

.width-30 {
  width: 30%;
}

.opacity-75 {
  opacity: 0.75;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-sm {
  font-size: 12px;
}

.text-xs {
  font-size: 10px;
}

.text-smd {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-xlg {
  font-size: 20px;
}

.text-xxlg {
  font-size: 22px;
}

.text-weight-lg {
  font-weight: 700;
}

.text-weight-md {
  font-weight: 500;
}

.text-weight-normal {
  font-weight: 400 !important;
}

.text-weight-lg {
  font-weight: 700;
}

.text-color-secondary {
  color: $text-color-secondary-bg;
}

.text-color-white {
  color: $color-white;
}

.text-color-warning {
  color: var(--text-validation-error);
}

.text-color-primary {
  color: $text-color-primary-bg;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.text-color-primary-pink {
  color: $color-primary;
}

.action-cursor {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}

.sr-only {
  // content just for screen readers
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.no-underline {
  text-decoration-line: none;
}
