.back-btn {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  margin-left: 30px;
  flex-shrink: 0;
  z-index: 5;
  border-color: transparent;
  cursor: pointer;
}

.back-btn img,
.back-btn .back-icon {
  margin-left: -3px;
}

.btn {
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #dfe3ed;
  background-color: $color-white;
  color: $text-color-primary-bg;
  text-align: center;
}

.btn:disabled {
  opacity: 0.7;
}

.btn.flat {
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.btn:hover {
  cursor: pointer;
}

.btn.btn-primary {
  background-color: $color-primary;
  color: $color-white;
  border: 1px solid $color-primary;
}

.btn.btn-bold {
  font-weight: 700;
}
