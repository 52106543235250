.page.view-past-consultation-page {
  .action-button {
    padding: 7px 12px;
    font-size: 12px;
    color: $color-primary;
    border: 1px solid #dfe3ed;
  }

  .past-consultation-section-text {
    line-height: 17px;
    font-weight: 400;
  }

  .consultation-attachment-uploaded-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .past-consultation-attach-wrapper {
    display: flex;
    align-items: center;
  }

  .past-consultation-attachment-wrapper {
    width: 150px;
    padding: 7px 15px;
    margin-bottom: 10px;
    border: 1px solid #dfe3ed;
    color: $color-primary;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
      width: 100%;
      line-height: 40px;
    }
  }

  .consultation-attachment-uploaded-list-item {
    width: 100%;

    @media screen and (max-width: $mobile) {
      line-height: 40px;
    }
  }
}

@media screen and (min-width: $tablet) {
  .page.view-past-consultation-page {
    .details-row {
      display: flex;
    }

    .details-row {
      .form-control,
      .consultation-need-to-speak {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 30px;
      }
    }

    .btn-container {
      margin: 0 auto;
      width: 280px;
      flex: none;
    }

    .files-list .list-item {
      flex: 0.5;
    }

    .past-consultation-attachment-wrapper:not(:last-of-type) {
      margin-right: 20px;
    }

    .consultation-attachment-uploaded-list {
      justify-content: start;
    }

    .consultation-attachment-uploaded-list-item {
      margin-right: 25px;
    }
  }
}
