.page-header {
  background: $color-header-primary-bg;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
}

.page-header .page-header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 83px;

  .page-header-text:nth-child(2) {
    padding-right: 62px;
  }
}

.page-header .page-header-wrap {
  @media screen and (max-width: $tablet) {
    .stepper {
      margin-top: -20px;
    }
  }
}

.page-header-text {
  color: $color-white;
  width: 100%;
  text-align: center;

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 768px) {
  .page-header .page-header-content.page-header-content-stepper {
    padding: 14px 0 0;
    min-height: auto;
  }
}

@media screen and (min-width: 1024px) {
  .page-header .page-header-wrap {
    max-width: 1016px;
    margin: 0 auto;
  }
}
