// Colour tokens
$coral-standard: #f4616b;
$tango-standard: #ffa70d;
$azure-standard: #4e74b7;
$green-standard: #12a583;

:root {
  // core tokens
  --color-white: #fff;
  --color-black: #000;

  // Azure
  --color-azure-10: #edf1f8;
  --color-azure-20: #dce3f1;
  --color-azure-30: #cad5e9;
  --color-azure-40: #b8c7e2;
  --color-azure-50: #a7badb;
  --color-azure-60: #95acd4;
  --color-azure-70: #839ecd;
  --color-azure-80: #7190c5;
  --color-azure-90: #6082be;
  --color-azure-standard: #4e74b7;
  --color-azure-110: #466ba5;
  --color-azure-120: #3e5d92;
  --color-azure-130: #375180;
  --color-azure-140: #2f466e;

  // Coral
  --color-coral-10: #feeff0;
  --color-coral-20: #fddfe1;
  --color-coral-30: #fcd0d3;
  --color-coral-40: #fbc0c4;
  --color-coral-50: #f9b0b5;
  --color-coral-60: #fba0a6;
  --color-coral-70: #f79097;
  --color-coral-80: #f68189;
  --color-coral-90: #f5717a;
  --color-coral-standard: #f4616b;
  --color-coral-110: #dc5760;
  --color-coral-120: #c34e56;
  --color-coral-130: #ab444b;
  --color-coral-140: #923a40;

  // Green
  --color-green-10: #e7f6f3;
  --color-green-20: #d0ede6;
  --color-green-30: #b8e4da;
  --color-green-40: #a0dbcd;
  --color-green-50: #89d2c1;
  --color-green-60: #71c9b5;
  --color-green-70: #59c0a8;
  --color-green-80: #41b79c;
  --color-green-90: #2aae8f;
  --color-green-standard: #12a583;
  --color-green-110: #109476;
  --color-green-120: #0e8469;
  --color-green-130: #0d745c;
  --color-green-140: #0b634f;

  // Grey
  --color-grey-5: #f7f8f8;
  --color-grey-10: #f0f1f2;
  --color-grey-20: #e0e2e5;
  --color-grey-30: #d1d4d8;
  --color-grey-40: #c1c6cb;
  --color-grey-50: #b2b8be;
  --color-grey-60: #a2a9b1;
  --color-grey-70: #939ba4;
  --color-grey-80: #838d97;
  --color-grey-90: #747e8a;
  --color-grey-standard: #64707d;
  --color-grey-110: #5a6571;
  --color-grey-120: #505a64;
  --color-grey-130: #464e58;
  --color-grey-140: #3c434b;

  // Lilac
  --color-lilac-10: #fbf3fd;
  --color-lilac-20: #f7e7fb;
  --color-lilac-30: #f3dbfa;
  --color-lilac-40: #efcff8;
  --color-lilac-50: #ebc4f6;
  --color-lilac-60: #e6b8f4;
  --color-lilac-70: #e2acf2;
  --color-lilac-80: #dea0f1;
  --color-lilac-90: #da94ef;
  --color-lilac-standard: #d688ed;
  --color-lilac-110: #c17ad5;
  --color-lilac-120: #ab6dbe;
  --color-lilac-130: #965fa6;
  --color-lilac-140: #80528e;

  // Mint
  --color-mint-10: #f4fbfa;
  --color-mint-20: #e8f8f4;
  --color-mint-30: #ddf4ef;
  --color-mint-40: #d1f1e9;
  --color-mint-50: #c6ede4;
  --color-mint-60: #bae9df;
  --color-mint-70: #aee6d9;
  --color-mint-80: #a3e2d4;
  --color-mint-90: #98dfce;
  --color-mint-standard: #8cdbc9;
  --color-mint-110: #7ec5b5;
  --color-mint-120: #70afa1;
  --color-mint-130: #62998d;
  --color-mint-140: #548379;

  // Metal
  --color-metal-10: #e9eaeb;
  --color-metal-20: #d3d5d7;
  --color-metal-30: #bdc0c3;
  --color-metal-40: #a7abaf;
  --color-metal-50: #91969b;
  --color-metal-60: #7c8188;
  --color-metal-70: #666c74;
  --color-metal-80: #505760;
  --color-metal-90: #3a424c;
  --color-metal-standard: #242d38;
  --color-metal-110: #202832;
  --color-metal-120: #1d242d;
  --color-metal-130: #192027;
  --color-metal-140: #161b22;

  // Navy
  --color-navy-10: #edeff2;
  --color-navy-20: #dadee4;
  --color-navy-30: #c8ced7;
  --color-navy-40: #b5bdc9;
  --color-navy-50: #a3acbc;
  --color-navy-60: #919caf;
  --color-navy-70: #7e8ca1;
  --color-navy-80: #6c7b94;
  --color-navy-90: #596a86;
  --color-navy-standard: #475a79;
  --color-navy-110: #40516d;
  --color-navy-120: #394861;
  --color-navy-130: #323f55;
  --color-navy-140: #2b3649;

  // Neon Blue
  --color-neon-blue-10: #eff0fe;
  --color-neon-blue-20: #dfe1fd;
  --color-neon-blue-30: #d0d3fc;
  --color-neon-blue-40: #c0c4fb;
  --color-neon-blue-50: #b0b5fa;
  --color-neon-blue-60: #a0a6f8;
  --color-neon-blue-70: #9097f7;
  --color-neon-blue-80: #8189f6;
  --color-neon-blue-90: #717af5;
  --color-neon-blue-standard: #616bf4;
  --color-neon-blue-110: #5760dc;
  --color-neon-blue-120: #4e56c3;
  --color-neon-blue-130: #444bab;
  --color-neon-blue-140: #3a4092;

  // Tango
  --color-tango-10: #fff6e7;
  --color-tango-20: #ffedcf;
  --color-tango-30: #ffe5b6;
  --color-tango-40: #ffdc9e;
  --color-tango-50: #ffd386;
  --color-tango-60: #ffca6e;
  --color-tango-70: #ffc156;
  --color-tango-80: #ffb93d;
  --color-tango-90: #ffb025;
  --color-tango-standard: #ffa70d;
  --color-tango-110: #e6960c;
  --color-tango-120: #cc860a;
  --color-tango-130: #b37509;
  --color-tango-140: #996408;

  // semantic tokens
  --text-validation-error: #bb4a52;
  --card-border-color: var(--color-white);
}
