.article-page {
  .content {
    padding: 20px;

    p {
      margin-bottom: 10px;
    }

    ul {
      list-style: inside;
    }

    ul li {
      margin-bottom: 10px;
      margin-left: 10px;
    }

    b {
      margin: 15px 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 22px;
    }

    .sub-resource-list-container {
      margin: 32px 0px;

      h3 {
        font-size: 1.125rem;
      }

      ul {
        list-style-type: none;

        li {
          margin-left: 0;
        }

        li:first-of-type {
          border-left: 3px solid $color-black;
          padding-left: 4px;
          margin-top: 15px;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .article-page {
    .content {
      padding: 0 35px;
    }
  }
}

@media screen and (min-width: $laptop) {
  .article-page {
    .content {
      padding: 10px 35px 0;
    }
  }
}
