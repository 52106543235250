.health-wellbeing-page {
  .content {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .page-title {
    padding: 30px 20px 10px;
  }

  .health-top {
    display: flex;
    flex-direction: column;
  }

  .health-bottom {
    display: flex;
    flex-direction: column;
  }

  .health-multiple {
    display: flex;
  }

  .health-multiple .health-card {
    flex: 1 1;
  }

  button.health-card-button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
  }

  .health-card {
    display: flex;
    flex-direction: column;
    height: 195px;
    margin: 10px;
  }

  .card-image-block {
    display: flex;
    align-items: flex-end;
    flex: 1 1;
    padding: 20px 12px;
  }

  .card-title {
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    color: $color-white;
  }

  .healthy-card-footer {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 10px;
    background-color: $color-white;
  }

  .card-footer-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $text-color-primary-bg;
  }

  .healthy-weight {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .healthy-body {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .exercise {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sleep {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sexual-health {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .eat-well {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  .health-wellbeing-page {
    .content {
      padding-left: 25px;
      padding-right: 25px;
    }

    .health-top {
      flex-direction: row;

      .health-multiple {
        order: 2;
      }

      .health-single {
        order: 1;
      }
    }

    .page-title {
      font-size: 22px;
      padding-left: 35px;
      padding-right: 35px;
    }

    .health-bottom {
      flex-direction: row;
    }

    .health-multiple {
      flex-direction: column;
    }

    .health-multiple,
    .health-single {
      flex: 1 1;
    }

    .health-multiple .health-card {
      height: 165px;
    }

    .health-single .health-card {
      height: 355px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .health-wellbeing-page {
    .content {
      padding: 10px 0;
    }

    .page-title {
      padding: 30px 10px 10px;
    }
  }
}
