// Articles landing page
.slide-one {
  background-image: url('../../images/articles/photo_conditions_medical_information.jpg');
}

.slide-two {
  background-image: url('../../images/articles/photo_health_wellbeing.jpg');
}

.slide-three {
  background-image: url('../../images/articles/photo_health_services.jpg');
}

// Health & wellbeing page
.health-wellbeing-page {
  .healthy-weight {
    background-image: url('../../images/articles/HealthyWeightPhone.jpg');
  }

  .healthy-body {
    background-image: url('../../images/articles/HealthyBodyMobile.jpg');
  }

  .exercise {
    background-image: url('../../images/articles/ExcericsePhone.jpg');
  }

  .sleep {
    background-image: url('../../images/articles/SleepMobile.jpg');
  }

  .sexual-health {
    background-image: url('../../images/articles/SexualHealthMobile.jpg');
  }

  .eat-well {
    background-image: url('../../images/articles/EatWellPhone.jpg');
  }
}

@media screen and (min-width: 768px) {
  .health-wellbeing-page {
    .healthy-weight {
      background-image: url('../../images/articles/healthy-weight.jpg');
    }

    .healthy-body {
      background-image: url('../../images/articles/health-body.jpg');
    }

    .exercise {
      background-image: url('../../images/articles/exercise.jpg');
    }

    .sleep {
      background-image: url('../../images/articles/sleep.jpg');
    }

    .sexual-health {
      background-image: url('../../images/articles/sexual-health.jpg');
    }

    .eat-well {
      background-image: url('../../images/articles/eat-well.jpg');
    }
  }
}