.join-consultation-page {
  margin-top: 85px;

  .content-padding {
    padding: 20px 0;
  }

  .section-title {
    color: $text-color-primary-bg;
  }

  .active-button {
    width: 280px;
  }

  a.active-button {
    color: white;
    text-decoration: none;
  }

  .btn-container {
    align-items: center;
  }
}
