@use 'sass:math';
@import '../../../../styles/ui/breakpoints.scss';
@import '../../../../styles/ui/colors.scss';
@import '../../../../styles/ui/spacing.scss';
@import '../../../../styles/ui/typography.scss';

@import '../../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.banner {
  padding: $baseline;
  color: $color-white;
  border-radius: $border-radius-m * 2;
  box-shadow: 2px 4px 16px -4px rgba(36, 45, 56, 0.16);

  &.warning {
    background: $tango-standard;
  }

  &.info {
    background: $azure-standard;
  }

  &.error {
    background: $coral-standard;
  }

  &.success {
    background: $green-standard;
  }

  .header {
    display: flex;
    font-size: var(--font-size-l);

    svg {
      flex-shrink: 0;
    }

    svg:first-of-type {
      margin-right: $baseline;
    }
  }

  .description {
    font-size: var(--font-size-l);
  }

  header+.description {
    margin: $baseline $baseline $baseline $baseline * 2.3;
  }
}