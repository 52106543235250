input[type='date']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.datepicker-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .datepicker-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.datepicker-container {
  // max-width: 250px;
  max-width: 325px;
  min-width: 310px;
  background-color: white;
  // padding: 5px;

  button {
    border: none;
    border-radius: 0;
    background-color: transparent;
    font-family: var(--font-family-primary);
  }

  .current-date-year-button:focus {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }

  .current-date-year-button {
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 18px;
    padding: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
  }

  button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
  }

  .date-view-header__navigation--date button {
    padding: 5px;
    font-size: 1rem;
  }

  .datepicker-header-container {
    background-color: #475a79;
    color: #fff;
    padding: 5px;
    button {
      color: #fff;
    }

    button:hover {
      background-color: lighten($color: #475a79, $amount: 5%);
    }
  }

  .datepicker-year-view__year {
    height: 45px;
    font-weight: 400;
    width: 32%;
  }

  .datepicker-view-container {
    padding: 5px;
  }

  .current-date-date-button {
    font-size: 24px;
    padding: 10px 5px;
  }

  .current-date-year-button {
    padding: 5px;
    font-size: 16px;
  }
  .datepicker-view {
    display: flex;
    max-width: 325px;
    // min-width: 310px;
    min-height: 230px;
    overflow-x: hidden;
    flex-direction: column;
    // justify-content: center;
  }

  .datepicker-view.datepicker-view__month {
    justify-content: center;
  }

  .date-view-header__day-name--wrapper {
    display: flex;
    width: 100%;
    // background-color: $color-primary-bg;
    padding: 10px 0;
  }

  .date-view-header__day-name {
    display: flex;
    flex: 1;
    text-align: center;
    color: $text-color-secondary-bg;

    font-weight: 500;
    justify-content: center;
    font-size: 14px;
  }

  .datepicker-month__day {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    border: none;
    border-radius: 0;
    background-color: transparent;
    line-height: 22px;
    padding: 5px;
    font-size: 0.75rem;
  }

  .datepicker-month__day--hidden {
    pointer-events: none;
    opacity: 0;
  }

  .datepicker-month__day--disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.38);
  }

  .datepicker-year-view__year--selected,
  .datepicker-month-view__month.datepicker-month-view__month--selected,
  .datepicker-month__day--selected {
    background-color: $color-primary;
    color: #fff;
  }

  button.datepicker-month__day:not(.datepicker-month__day--selected):hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .datepicker-month__week {
    display: flex;
  }

  .year-view-header__navigation--wrapper,
  .date-view-header__navigation--wrapper {
    display: flex;
  }

  .year-view-header__navigation--date,
  .date-view-header__navigation--date {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  // .datepicker__month-view {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }

  .datepicker-view.datepicker-view__year {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .datepicker-month-view__month {
    width: 32%;
    text-align: center;
    // color: $text-color-secondary-bg;
    border: none;
    border-radius: 0;
    background-color: transparent;
    line-height: 20px;
    padding: 10px 0;

    font-weight: 500;
  }

  .datepicker-month-view__month--row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  .date-view-header__navigation--button,
  .year-view-header__navigation--button {
    height: 32px;
    width: 32px;
  }

  .datepicker-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .datepicker-toolbar .btn {
    flex: 1;
  }
}
