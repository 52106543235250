.page.emergency-disclaimer-page {
  .section-title {
    font-size: 18px;
    line-height: 22px;
    color: $text-color-primary-bg;
  }

  .emergency-disclaimer-paragraph {
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 14px;
  }

  .local-emergency-services-para {
    padding-top: 0;
  }

  .emergency-disclaimer-list-wrapper {
    padding: 25px;
    background-color: $color-white;
    font-size: 14px;
  }

  .emergency-disclaimer-list-wrapper li {
    margin-bottom: 10px;
  }

  .emergency-disclaimer-separate-line {
    width: 90%;
    height: 1px;
    margin: auto;
    background-color: #dfe2ed;
  }

  .section.bottom-text {
    margin-top: 0;
  }
}
