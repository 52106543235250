.side-menu {
  width: 330px;

  .profile-info-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .user-details-line {
    padding: 5px 0;
    max-width: 140px;
  }

  .side-menu-inner-part {
    position: fixed;
    width: 330px;
    max-height: 100%;
    overflow: auto;
    padding-bottom: 140px;
  }

  .profile-info-wrapper .user-details {
    word-break: break-word;
  }

  .profile-edit-text {
    cursor: pointer;
    text-decoration: underline;
  }

  .profile-info-wrapper .paper-text {
    flex: 1;
    width: 200px;
  }

  .profile-menu-list-wrapper {
    width: 100%;
  }

  .profile-menu-list-item {
    position: relative;
    display: flex;
  }

  .profile-menu-list-item .paper-header-top {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }

  .profile-menu-list-item-link {
    flex: 1;
    padding: 15px 5px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: $text-color-primary-bg;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .profile-menu-list-item {
    &:last-child {
      border-top: 1px solid #dfe2ed;
    }

    &:nth-child(1) {
      border: none;
    }
  }

  .edit-profile-nav-item {
    border-bottom: 1px solid #dfe2ed;
  }

  .second-profile-menu-list .profile-menu-list-item:last-child {
    margin-top: 20px;
  }

  .edit-profile-nav-item:after {
    width: 100%;
    margin-top: 10 px;
  }

  .edit-profile-nav-item {
    text-decoration: none;
    display: block;
    padding: 0 5px;
  }

  .edit-profile-nav-item .paper-header {
    color: $text-color-primary-bg;
  }

  .paper-content {
    border: 1px solid #dfe2ed;
  }
}

@media screen and (max-width: 1023px) {
  .side-menu {
    display: none;
  }
}

@media screen and (max-height: 720px) {
  .side-menu {
    .profile-menu-list-item-link {
      padding: 10px 5px;
    }
  }
}
