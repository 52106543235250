.pin-input-root {
  .pin-input-view {
    margin-top: 15px;
  }

  label {
    font-size: 16px;
    font-weight: 700;
    color: $text-color-primary-bg;

    span {
      padding: 5px 0;
      display: inline-block;
    }
  }

  .pin-description,
  .pin-input-description {
    color: $text-color-primary-bg;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;

    ul {
      list-style-type: disc;
      margin: 10px 0 10px 30px;
    }
  }

  .error-message {
    margin-top: 5px;
    font-size: 0.75rem;
    color: var(--text-validation-error);
  }

  .pin-input-view {
    display: flex;
    min-height: 22px;
  }

  .pin-input-symbol {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 24px;
    border-bottom: 4px solid #8a93a1;
  }

  .pin-input-symbol-value {
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 2px;
  }

  .pin-input-symbol:not(:first-child) {
    margin-left: 10px;
  }

  .pin-input-view.focused .pin-input-symbol.current {
    border-bottom-color: $color-primary;
  }

  .pin-input-value-holder {
    opacity: 0;
  }

  .pin-input-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    &:hover {
      color: $color-primary;
      cursor: pointer;

      path.fg {
        fill: $color-primary !important;
      }
    }
  }
}
