@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.bannerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  background-color: $color-warning;
  border: $border-width-s solid $color-warning;
  color: $color-white;
  position: relative;
  border-radius: 10px;
  min-height: 40px;
  padding: math.div($baseline, 2); // 8px
  font-size: var(--font-size-s);
  display: flex;
  flex: 1;
  margin-left: math.div($baseline, 1.3333333333); // 12px

  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: $color-warning;
  }
  &::after {
    border-width: 10px;
    margin-top: -10px;
  }
  &::before {
    border-width: math.div($baseline, 2); // 8px;
    margin-top: -(math.div($baseline, 2)); // - 8px;
  }
}
