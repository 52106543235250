.notification-item-wrapper {
  width: 100%;
  min-height: 40px;
  padding: 25px;
  transition: all 0.2s ease-in-out;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: $color-white;
}

.notification-item-success {
  background-color: $color-success;
}

.notification-item-error {
  background-color: $color-warning;
}

.notification-item-info {
  background-color: #2f96b4;
}

.notification-item-warning {
  background-color: #f89406;
}

@media screen and (min-width: 1024px) {
  .notification-item-wrapper {
    padding: 30px 25px 25px 25px;
  }
}
