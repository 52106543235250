// Stores the shared styles that are used in edit upcoming consultation and view past consultation
.consultation-info {
  .consultation-info-item-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 12px;
    padding: 13px;
    border-top: 1px solid #e1e5ee;
  }
  .consultation-info-item-wrapper div {
    flex: 1 1;
  }
  .consultation-info-item-data {
    text-align: right;

    @media screen and (min-width: $tablet) {
      text-align: left;
    }
  }
  .consultation-info-item-wrapper:last-child {
    border-bottom: 1px solid #e1e5ee;
  }
  .consultation-info-item-title {
    font-weight: 500;
  }
}

.consultation-link-notice {
  margin-top: $baseline * 2;
  font-size: var(--font-size-xs);
  text-align: center;
}

.consultation-symptoms {
  margin-top: 30px;
}

.consultation-need-to-speak {
  margin-bottom: 30px;
}

.consultation-attachment-uploaded-list-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e1e5ee;
  padding: 15px 0;
}

.consultation-attachment-uploaded-list-item .list-item-title {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
