.book-single-phone-consultation-page {
  margin-top: -47px;

  .book-single-phone-consultation-section {
    display: flex;
    min-height: 270px;
  }

  .book-single-phone-consultation-section.SecondOpinion,
  .book-single-phone-consultation-section.Counsellor,
  .book-single-phone-consultation-section.Physiotherapist {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .book-single-phone-consultation-header {
    margin-top: auto;
    color: $color-white;

    .icon-wrapper {
      width: 60px;
      height: 60px;
      background: $color-icon;
      border-radius: 50%;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }

  .book-single-phone-consultation-title {
    font-size: 20px;
    font-weight: 700;
  }

  .note-wrapper {
    align-items: center;
  }

  .note-icon {
    padding-right: 20px;
  }

  .accordion-wrapper {
    .accordion-item {
      border-top: 1px solid #dfe2ed;
      border-bottom: 1px solid #dfe2ed;
    }

    summary {
      padding: 10px 0;
      cursor: pointer;
      transition: 0.4s;
      font-size: 1rem;
      font-weight: 700;
      transition: 0s all ease;
      list-style-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjA2IDMuMDZBMS41IDEuNSAwIDAgMCAxMi45NC45NGwyLjEyIDIuMTJaTTggOCA2Ljk0IDkuMDZhMS41IDEuNSAwIDAgMCAyLjEyIDBMOCA4Wk0zLjA2Ljk0QTEuNSAxLjUgMCAwIDAgLjk0IDMuMDZMMy4wNi45NFptOS44OCAwLTYgNiAyLjEyIDIuMTIgNi02TDEyLjk0Ljk0Wm0tMy44OCA2LTYtNkwuOTQgMy4wNmw2IDYgMi4xMi0yLjEyWiIgZmlsbD0iIzI0MkQzOCIvPjwvc3ZnPg=='); // chevron down
    }

    details[open] > summary {
      list-style-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS45NCA2Ljk0YTEuNSAxLjUgMCAxIDAgMi4xMiAyLjEyTC45NCA2Ljk0Wk04IDIgOS4wNi45NGExLjUgMS41IDAgMCAwLTIuMTIgMEw4IDJabTQuOTQgNy4wNmExLjUgMS41IDAgMCAwIDIuMTItMi4xMmwtMi4xMiAyLjEyWm0tOS44OCAwIDYtNkw2Ljk0Ljk0bC02IDYgMi4xMiAyLjEyWm0zLjg4LTYgNiA2IDIuMTItMi4xMi02LTYtMi4xMiAyLjEyWiIgZmlsbD0iIzI0MkQzOCIvPjwvc3ZnPg=='); // chevron up
    }

    div {
      padding: 16px 16px 32px;
      font-size: 1rem;

      p {
        margin-bottom: 16px;
      }

      ul {
        margin-bottom: 16px;

        li {
          line-height: 1.3;
          margin-bottom: 4px;
        }
      }
    }
  }

  .call-to-book-link {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-decoration: none;
    color: $text-color-primary-bg;
  }

  .call-to-book-phone-number {
    padding-bottom: 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  .call-to-book-desktop-card {
    display: block;
  }

  .call-to-book-desktop-card .card-footer div {
    font-weight: 400;
    font-size: 12px;
  }

  li {
    list-style-type: disc;
    margin-left: 15px;
  }
}

@media screen and (min-width: $tablet) {
  .book-single-phone-consultation-page {
    .book-single-phone-consultation-section {
      min-height: 550px;
    }

    .book-single-phone-consultation-title {
      font-size: 40px;
    }

    .book-single-phone-consultation-subtitle {
      font-size: 18px;
      margin-bottom: 50px;
    }

    .note-section {
      display: flex;

      .note-wrapper {
        flex: 1 1;
      }

      .note-wrapper:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (min-width: $laptop) {
  .book-single-phone-consultation-page {
    margin-top: 0;

    .book-single-phone-consultation-section {
      min-height: 325px;
      margin-left: 25px;
      margin-right: 25px;
    }

    .book-single-phone-consultation-header {
      display: flex;
      align-items: center;
    }

    .book-single-phone-consultation-title-wrapper {
      order: 1;
    }

    .icon-wrapper {
      order: 2;
    }
  }
}
