.section-title {
  font-size: var(--font-size-xl);
  color: var(--color-metal-standard);
  font-weight: 700;
}

.section {
  padding: 0 25px;
  margin-top: 30px;
}

.section-title-container {
  padding: 10px;
}

.section-subtitle {
  font-size: 18px;
  color: $text-color-primary-bg; //#242d38;
  font-family: var(--font-family-primary);
  font-weight: 600;
  margin-top: 10px;
}

.horizontal-scroll {
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  position: relative;
}

.pad-items-left > *:not(:first-child) {
  margin-left: 10px;
}

.horizontal-scroll.inline {
  min-width: auto;
}

.tooltip {
  position: relative;
  border: 1px solid $color-primary;
  border-radius: 10px;
  background-color: $color-primary;
  min-height: 40px;
  color: #fff;
  padding: 8px;
  font-size: 14px;
  display: flex;
  flex: 1;
}

.tooltip.warning {
  background-color: $color-warning;
  border: 1px solid $color-warning;
}

.tooltip:after,
.tooltip:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip.warning:after {
  border-right-color: $color-warning;
}

.tooltip.warning:before {
  border-right-color: $color-warning;
}

.tooltip:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: $color-primary;
  border-width: 10px;
  margin-top: -10px;
}

.tooltip:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: $color-primary;
  border-width: 8px;
  margin-top: -8px;
}

.section-slider {
  margin-top: 30px;
  transition: all 0.2s ease-in-out;
}

.section-slider-title-container {
  margin: 0 25px 15px;
}

.single-line-input-wrapper {
  display: flex;
  justify-content: space-between;
}

.text-clipping {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grecaptcha-badge {
  visibility: hidden;
}
