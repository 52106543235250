.health-services-page {
  .map-wrapper {
    height: 58vh;
    width: 100%;
  }

  .health-service-paper-content {
    display: flex;
    align-items: center;
    padding: 0 10px;

    .paper-header {
      font-weight: 400;
    }
  }

  .type-pick .paper-header.selected {
    color: $color-primary;
  }

  .location-pick .paper-header.selected {
    color: $color-primary;
  }

  .form-control {
    flex: 1 1;
  }

  .search-for-wrapper {
    flex-wrap: wrap;
    max-width: 325px;
    margin: auto;
  }

  .type-pick {
    min-height: 39px;
    margin-bottom: 10px;
  }

  .type-pick:nth-child(1) {
    margin-right: 10px;
  }

  .type-pick:nth-child(3) {
    margin-right: 10px;
  }
}

@media screen and (min-width: 768px) {
  .health-services-page {
    .search-for-wrapper {
      flex-wrap: nowrap;
      max-width: none;
    }

    .type-pick:not(:last-child) {
      margin-right: 30px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .health-services-page {
    .page-wrapper {
      padding-top: 80px;
      justify-content: center;
    }

    .page-content {
      margin-top: 30px;
    }

    .loggedOutContent {
      width: auto !important;
    }

    .map-wrapper {
      height: 40vh;
    }

    .search-for-wrapper {
      max-width: none;
    }
  }
}
