.contact-information {
  .section-divider {
    width: calc(100% - 50px);
    height: 1px;
    margin: 0 auto;
    margin-top: 32px;
    background-color: #dfe2ed;
  }

  .city-post-line .form-control {
    width: 100%;
  }

  .load-file-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .load-file-text {
    font-size: 14px;
  }

  .load-file-label {
    padding: 8px 12px;
    color: $color-primary;
    border: 1px solid #dfe3ed;
  }

  .form-control label {
    display: flex;
    align-items: initial;
  }

  .company-number-symbol {
    padding-right: 10px;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family-primary);
  }

  .text-input-wrapper {
    display: flex;
  }

  .form-control.contact-information-list-input {
    margin-bottom: 20px;
  }

  .contact-information-list-input button {
    padding: 10px 15px;
  }

  .contact-information-list-input .text-input-wrapper {
    padding-right: 0;
  }

  // Extracted so can be removed in the future
  .profile-choose-service-link {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border: 1px solid #e7e7e7;
    text-decoration: none;
    color: $color-primary;

    svg {
      margin-right: 8px;
    }
  }

  .form-control {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: $tablet) {
  .contact-information {
    .details-row {
      display: flex;

      .mt-1-5 {
        margin-top: 0px;
      }
    }

    .details-row {
      .form-control {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 25px;
      }
    }

    .load-file-wrapper {
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 640px) {
  .contact-information {
    .service-details .form-control {
      margin-bottom: $baseline * 0.5;
    }

    .form-control {
      margin-bottom: 0;
    }

    .details-row {
      display: flex;

      .mt-1-5 {
        margin-top: 0px;
      }
    }

    .datepicker-root {
      display: flex;
      flex: 1;
    }

    .gender-form-control {
      margin-right: 10px;
    }

    .details-row {
      margin-bottom: 20px;

      .form-control {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 25px;
      }
    }

    .btn-container {
      margin: 0 auto;
      width: 450px;
    }

    .new-password-modal-content {
      max-width: 50%;
    }
  }
}

@media screen and (min-width: $laptop) {
  .contact-information {
    .details-row {
      display: flex;

      .mt-1-5 {
        margin-top: 15px;
      }
    }
  }
}
