.profile-page {
  .profile-info-wrapper {
    display: flex;
    align-items: flex-end;

    .profile-info-personal {
      color: #64707d;
      margin: 16px 16px 0 0;
      font-size: 0.875rem;
      line-height: 1.28;
    }
  }

  .profile-info-wrapper .paper-text {
    flex: 1;
  }

  .profile-menu-list-wrapper {
    width: 100%;
  }

  .profile-menu-list-item {
    position: relative;
  }

  .profile-menu-list-item-link {
    flex: 1;
    padding: 20px 16px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: $text-color-primary-bg;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .page-content .profile-menu-list-item:not(:last-child):after {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    height: 1px;
    margin: auto;
    background-color: #e7e7e7;
  }

  .edit-profile-nav-item {
    text-decoration: none;
    display: flex;
    justify-content: space-between;

    .paper-header {
      color: $text-color-primary-bg;
    }

    & div:last-child {
      align-self: center;
    }
  }
}

@media screen and (max-height: 620px) {
  .profile-page {
    .page-content {
      padding-bottom: 80px;
    }
  }
}
