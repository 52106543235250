.service-card-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 12px 20px;
  margin-bottom: 20px;
  background-color: $color-white;
  transition: all 0.5s ease-in-out;

  .anchor-elem {
    display: block;
    position: absolute;
    top: -90px;
    visibility: hidden;
  }

  .service-card-name {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 19px;
    color: $text-color-primary-bg;
  }

  .service-card-address {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 14px;
    color: $text-color-secondary-bg;

    svg path {
      fill: $text-color-secondary-bg;
    }
  }

  .service-card-buttons {
    display: flex;
    margin-bottom: 20px;

    button {
      flex: 1;
    }
  }

  .service-card-details-wrapper {
    padding-top: 20px;
    border-top: 1px solid #e7e7e7;
    transition: all 0.5s ease-in-out;

    .detail-row {
      display: flex;
      align-items: center;
      color: $text-color-primary-bg;
      font-size: 14px;
      line-height: 16px;

      svg {
        margin-right: 10px;
      }

      a,
      a:active {
        color: $text-color-primary-bg;
      }
    }

    .custom-phone-icon {
      width: 15px;
      height: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .service-card-wrapper {
    .btn {
      padding: 7px 10px;
    }
  }
}
