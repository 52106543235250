.article-icon {
  width: 24px;
  height: 24px;
}

.profile-icon {
  width: 21px;
  height: 24px;
}

.allergy-reminder-icon {
  width: 33px;
  height: 33px;

  circle {
    fill: $color-primary;
  }
}

.for-you-icon {
  width: 28px;
  height: 24px;
}

.gp-consultation-icon {
  width: 22px;
  height: 22px;
}

.delete-photo-icon {
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.calendar-icon {
  width: 12px;
  height: 14px;
}

.notification-icon {
  width: 14px;
  height: 14px;
}

.tick-icon {
  width: 10px;
  height: 8px;
}

.tick-icon.large-tick {
  width: 30px;
  height: 24px;
}

.tick-icon.success .fill-tick {
  fill: $color-success;
}

.prescription-icon {
  width: 24px;
  height: 25px;
}

.appointment-booking-icon {
  width: 22px;
  height: 21px;
}

.phone-call-icon {
  width: 24px;
  height: 24px;

  &.white {
    path {
      stroke: $color-white;
    }
  }
}

.message-icon {
  width: 24px;
  height: 22px;
}

.video-call-icon {
  width: 26px;
  height: 24px;

  &.white {
    path {
      stroke: $color-white;
    }
  }
}

.emotional-wellbeing-icon {
  width: 43px;
  height: 43px;
}

.aches-and-pains-icon {
  width: 43px;
  height: 43px;
}

.second-medical-opinion-icon {
  width: 43px;
  height: 43px;
}

.close-icon {
  width: 16px;
  height: 16px;
}

.chevron-icon {
  width: 18px;
  height: 18px;
}

.right-chevron-icon {
  width: 8px;
  height: 12px;
}

.small-chevron-icon {
  width: 6px;
  height: 8px;
}

.tab-icon {
  width: 43px;
  height: 43px;
}

.mobile-icon {
  width: 43px;
  height: 43px;
}

.speach-marks-icon {
  width: 28px;
  height: 24px;
}

.error-input-icon {
  width: 13px;
  height: 13px;
}

.download-attachment-icon {
  width: 15px;
  height: 16px;

  path {
    fill: $color-primary;
  }
}

.services-way-icon {
  width: 43px;
  height: 43px;
}

.services-phone-icon {
  width: 43px;
  height: 43px;
}

.terms-and-conditions-icon {
  width: 46px;
  height: 46px;

  circle {
    fill: $color-primary;
  }
}

.attachment-icon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
}

.location-icon {
  width: 10px;
  height: 15px;

  path {
    fill: $color-primary;
  }
}

.internet-icon {
  width: 16px;
  height: 16px;
}

.loupe-icon {
  width: 16px;
  height: 16px;
}

.error-icon-sm-size {
  width: 15px;
  height: 15px;
}

.error-icon.white {
  path.bg {
    fill: $color-white;
  }
}

.icon-lock {
  vertical-align: bottom;
}
