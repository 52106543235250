@use 'sass:math';
@import '../../../../../core/styles/ui/breakpoints.scss';
@import '../../../../../core/styles/ui/colors.scss';
@import '../../../../../core/styles/ui/spacing.scss';
@import '../../../../../core/styles/ui/typography.scss';

@import '../../../../../core/styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

@import '~@core/styles/app.scss';

@import 'pages/book-single-phone-consultation.scss';
@import 'pages/articles.scss';
@import './slider.scss';

// OVERWRITE
@import 'overwrite/icons.scss';
@import 'overwrite/form.scss';
@import 'overwrite/buttons.scss';

body {
  font-family: var(--font-family-primary);
  color: $text-color-primary-bg;
}

.slide-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.custom-tooltip-initialize {
  width: 250px;
  margin-left: 10px !important;
  white-space: break-spaces;
}

.book-tooltip-text {
  overflow: hidden;
}
