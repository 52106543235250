@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.slideInContent {
  width: 100%;
  color: $color-white;

  .icons {
    margin-top: $baseline * 1.75;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:first-child) {
        margin-left: $baseline * 2.25;
      }

      .iconImgContainer {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $color-white;
        border-radius: 50%;

        path,
        g {
          stroke: $color-white;
        }
      }
    }
  }

  .slideTwo {
    margin: auto;

    ul {
      max-width: 300px;
      margin: $baseline auto 0;

      @media screen and (min-width: $tablet) {
        max-width: 380px;
      }

      li {
        padding: 0 0 $baseline 0;
        display: flex;
        align-items: flex-start;

        &::before {
          content: '';
          display: inline-block;
          transform: rotate(45deg);
          flex-shrink: 0;
          height: 10px;
          width: 5px;
          border-bottom: 3px solid $color-white;
          border-right: 3px solid $color-white;
          margin-right: $baseline * 0.75;
        }
      }
    }
  }
}

.pagerDots {
  display: flex;
  justify-content: center;

  .pagerDot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #e7eaf2;

    .indicator {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &.active .indicator {
      background-color: $color-primary;
    }

    &:not(:first-child) {
      margin-left: 0.75rem;
    }
    &:not(:last-child) {
      margin-right: 0.75rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
