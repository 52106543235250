.App {
  display: grid;
  grid-template-rows: 1fr minmax(min-content, max-content);
  min-height: 100vh; // falls back to vh if svh are not supported
  min-height: 100svh;
  grid-template-areas:
    'content'
    'footer';

  @media screen and (max-width: $laptop) {
    grid-template-rows: 1fr minmax(56px, max-content); // 56px is the space for sticky footer
  }
}

.page {
  width: 100%;
  background-color: $color-primary-bg;
  background-image: none;
  overflow: auto;
}

.page-wrapper {
  max-width: 1016px;
  margin: 0 auto 0;
  padding-top: 100px;

  @media screen and (min-width: $laptop) {
    display: grid;
    grid-template-columns: 344px 1fr;

    &.no-sidebar {
      grid-template-columns: 1fr;
    }
  }
}

.page-content {
  transition: all 0.2s ease-in-out;
  padding-bottom: $baseline * 2;

  @media (min-width: $laptop) {
    width: 672px;
  }
}

.no-sidebar .page-content {
  margin-left: auto;
  margin-right: auto;
}

.page.page-general {
  .page-content {
    margin-top: 25px;
  }
}
