.edit-gp-consultation-page {
  .scheduled-time-wrapper {
    span[data-testid='add-to-calendar'] {
      margin-left: $baseline;

      @media screen and (max-width: $mobile) {
        display: block;
        margin: $baseline 0 0;
      }
    }
  }

  .action-button {
    padding: 7px 12px;
    font-size: 12px;
    color: $color-primary;
    border: 1px solid #dfe3ed;
  }

  .consultation-cancel-button {
    background: transparent;
  }

  .consultation-need-to-speak-phone-number {
    a {
      text-decoration: none;
      color: $color-primary;
    }
  }

  .load-file-button input {
    display: none;
    font-size: 12px;
  }

  .consultation-cancel-subtitle {
    max-width: 65%;
  }

  .consultation-save-wrapper {
    margin: 20px 0;
  }

  .edit-gp-consultation-page-current-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .edit-gp-consultation-page-current-location-address {
    color: $text-color-primary-bg;
  }

  .edit-gp-consultation-page-current-location-edit-button {
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
  }

  .city-post-line .form-control {
    width: 100%;
  }

  .btn-container {
    padding: 0;
  }
}

.contact-cancel-container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

@media screen and (min-width: $mobile) {
  .consultation-cancel {
    width: 50%;
  }
}

.consultation-attachment-upload {
  padding: 16px 0;
}

.edit-gp-consultation-page-title {
  font-size: 22px;

  @media screen and (max-width: $mobile) {
    font-size: 16px;
  }
}

@media screen and (min-width: $tablet) {
  .edit-gp-consultation-page {
    .details-row {
      display: flex;
    }

    .details-row {
      .form-control {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 30px;
      }
    }

    .btn-container {
      margin: 0 auto;
      width: 280px;
      flex: none;
    }

    .files-list .list-item {
      flex: 0.5;
    }

    .paper-header-title {
      width: 100%;
      margin-left: 10px;
    }

    .day-slot {
      margin-right: 15px;
    }

    .city-post-line {
      width: 100%;
      flex-direction: row;
    }

    .native-select-container {
      margin-left: 10px;
    }
  }
}
