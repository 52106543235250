.verify-email-page {
  padding-top: 80px;

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h2,
  p {
    margin-bottom: $baseline;
  }

  .verify-page-button-container {
    margin-top: $baseline * 2;
    display: flex;
    justify-content: center;
  }
}
