.page.account-created-page {
  .page-wrapper {
    text-align: center;
    max-width: 760px;
    margin: 83px 48px 0;
    padding-top: 10vh;

    @media screen and (min-width: 768px) {
      padding-top: 100px;
      margin: 83px auto 0;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 28px;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 32px;
    }
  }

  p {
    margin-bottom: 28px;
    font-size: 0.875rem;

    @media screen and (min-width: 768px) {
      font-size: 1rem;
      margin-bottom: 32px;
    }
  }

  .btn-container {
    margin: 96px auto 44px;
    padding: 0;
    width: 280px;
  }
}
