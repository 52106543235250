.page.about-page {
  .about-page-title {
    font-size: 18px;
    line-height: 22px;
  }

  .about-page-text {
    font-size: 14px;
    line-height: 17px;
  }

  .about-page-link-item {
    margin-bottom: 10px;
    color: $text-color-primary-bg;
  }

  .about-page-link-item a {
    color: $text-color-primary-bg;
    font-size: 14px;
  }
}
