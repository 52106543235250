.articles-page {
  .page-wrapper {
    .content {
      @media screen and (max-width: $laptop) {
        margin-top: -83px;
      }
    }
  }

  .page-title {
    display: none;
    margin: 30px 0;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0px;
  }

  .slide-container {
    display: block;
  }

  .non-slide-container {
    display: none;
  }

  .slide-content {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  .slide-one {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .slide-two {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .slide-three {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .content {
    flex: 2 1 auto;
  }

  .dots-wrapper {
    flex: 1 1 auto;
    text-align: center;
  }

  .dots-wrapper-text {
    font-size: 10px;
    color: $text-color-secondary-bg;
  }

  .slide-content-top {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    padding: 30px;
  }

  .slide-content-top-title {
    font-size: 26px;
  }

  .slide-content-top-subtitle p {
    display: flex;
    align-items: center;
  }

  .slide-content-bottom {
    padding: 15px 20px 0;
    height: 10%;
    min-height: 60px;
  }

  .slide-content-bottom .btn {
    padding: 0 10px;
    border-left: none;
  }

  .slide-content-bottom .form-control {
    flex: 1 1;
  }

  .slide-content-bottom .text-input-wrapper {
    border-right: none;
  }

  .article-link {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  .article-link-full {
    height: 90%;
  }

  .article-link-primary {
    color: $text-color-primary-bg;
    text-decoration: none;
  }

  .active-article-link {
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .articles-page {
    .slide-content-top {
      padding: 55px;
    }

    .slide-content-top-title {
      font-size: 40px;
      max-width: 370px;
    }

    .slide-content-top-subtitle p {
      font-size: 18px;
      max-width: 420px;
    }

    .slide-content-bottom {
      font-size: 21px;
      padding: 40px 55px 0;
      min-height: 85px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .articles-page {
    .slide-container {
      display: none;
    }

    .slide-content {
      height: 325px;
    }

    .non-slide-container {
      display: flex;
      flex-direction: column;

      .slide-content-top {
        padding: 20px;
      }

      .non-slide-item-top .slide-content-bottom {
        padding: 0;
      }

      .non-slide-item-bottom .slide-content-bottom {
        padding: 10px 20px;
        background: #fff;
        color: $text-color-primary-bg;
      }

      .slide-content-bottom {
        min-height: auto;
      }

      .slide-content-bottom p {
        font-size: 16px;
      }

      .slide-content-top-title {
        font-size: 26px;
      }

      .slide-content-top-subtitle p {
        font-size: 14px;
      }
    }

    .non-slide-item-bottom {
      display: flex;
    }

    .page-title {
      display: block;
    }

    .dots-wrapper {
      display: none;
    }
  }
}
