.page-symptom-checker {
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }

  h2,
  h3 {
    font-size: var(--font-size-xxl);
  }

  #Traversal {
    @media screen and (max-width: $mobile) {
      padding-bottom: 80px;
    }

    div {
      font-size: var(--font-size-l);
    }

    // Styles for `Summary` button
    & > div:last-of-type > div:last-of-type button {
      background: #ffffff;
      box-shadow: 2px 4px 8px rgba(115, 156, 222, 0.15);
      border: 1px solid #e7e7e7;
      color: #242d38;
    }

    fieldset {
      button {
        background: none; // overides styles on line 59 for the info icon
      }

      label div,
      input[type='number']:focus,
      input[type='date'],
      table tr td > div > div {
        box-shadow: none; // Removes #00c4fa
      }

      // Changes hover colour of Info icon instead of #00C4FA
      &>div>div,
      // icon in question heading
      &>div>label div:last-child,
      // icon in label options
      & table tr th {
        // icon in table
        :hover svg {
          stroke: #f4616b;

          :hover {
            stroke: #f4616b;
          }
        }
      }
    }

    // Navigation buttons - next and previous
    button {
      background: #f4616b;
      color: #ffffff;
      font-weight: 700;
      border-radius: 4px;

      &[disabled] {
        background: #e7e7e7;
        color: #64707d;
      }
    }

    label:last-of-type {
      box-shadow: none; // removes last divider on options
    }

    h2,
    h3 {
      font-weight: bold;
    }

    ul {
      margin: 10px 0 10px 20px;

      li {
        list-style: disc;
        padding-bottom: 10px;
      }
    }

    // open/close chevron
    .iuAclX {
      color: white;
      stroke: white;
    }

    // remove left to right transitions
    div {
      transform: none !important;
    }

    @media screen and (max-width: $mobile) {
      div[class*='PanelTitle'] {
        font-size: 18px !important;
        line-height: 1.2;
      }
    }
  }

  #traversal {
    h2 {
      display: none;
    }

    ul {
      margin: 10px 0 10px 20px;

      li {
        list-style: disc;
        padding-bottom: 10px;
      }
    }
  }

  #hcmodalcontent {
    line-height: 1.45;
    margin-bottom: 20px;
  }
}
