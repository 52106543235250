/**
 * Typography
 * Figma designs: https://www.figma.com/file/HX9GJbfdWfa8Gp46yFlJCC/PWA-Design-System?node-id=264%3A2190
 */

:root {
  --font-family-primary: 'Roboto', sans-serif;
  --font-family-secondary: 'Helvetica', sans-serif;

  --font-size-xxs: 0.625rem; // 10px
  --font-size-xs: 0.75rem; // 12px
  --font-size-s: 0.875rem; // 14px
  --font-size-m: 1rem;
  --font-size-l: 1.125rem; // 18px
  --font-size-xl: 1.25rem; // 20px
  --font-size-xxl: 1.5rem; // 24px
  --font-size-3xl: 1.75rem; // 28px
  --font-size-4xl: 2rem; // 32px

  // Headings
  --text-heading-m-size: var(--font-size-xxl);
  --text-heading-m-weight: 700;
  --text-heading-m-line-height: 1.5;

  --text-heading-s-size: var(--font-size-xl);
  --text-heading-s-weight: 700;
  --text-heading-s-line-height: 1.4;

  --text-heading-xs-size: var(--font-size-l);
  --text-heading-xs-weight: 700;
  --text-heading-xs-line-height: calc(4 / 3);

  // Body
  --text-body-l-size: var(--font-size-l);
  --text-body-l-weight: 400;
  --text-body-l-line-height: calc(4 / 3);

  --text-body-m-size: var(--font-size-m);
  --text-body-m-weight: 300;
  --text-body-m-line-height: 1.5;

  --text-body-s-size: var(--font-size-s);
  --text-body-s-weight: 400;
  --text-body-s-line-height: 1.42;

  // Small print
  --text-small-print-size: var(--font-size-xs);
  --text-small-print-weight: 400;
  --text-small-print-height: 1.5;

  // Links
  --text-link-m-size: var(--font-size-m);
  --text-link-m-weight: 400;
  --text-link-m-line-height: 1.5;

  --text-link-s-size: var(--font-size-s);
  --text-link-s-weight: 400;
  --text-link-s-line-height: 1.42;
}

// Components

.text-heading-m {
  font-size: var(--text-heading-m-size);
  font-weight: var(--text-heading-m-weight);
  line-height: var(--text-heading-m-line-height);
}

.text-heading-s {
  font-size: var(--text-heading-s-size);
  font-weight: var(--text-heading-s-weight);
  line-height: var(--text-heading-s-line-height);
}

.text-heading-xs {
  font-size: var(--text-heading-xs-size);
  font-weight: var(--text-heading-xs-weight);
  line-height: var(--text-heading-xs-line-height);
}

.text-body-l {
  font-size: var(--text-body-l-size);
  font-weight: var(--text-body-l-weight);
  line-height: var(--text-body-l-line-height);
}

.text-body-m {
  font-size: var(--text-body-m-size);
  font-weight: var(--text-body-m-weight);
  line-height: var(--text-body-m-line-height);
}

.text-body-s {
  font-size: var(--text-body-s-size);
  font-weight: var(--text-body-s-weight);
  line-height: var(--text-body-s-line-height);
}

.text-small-print {
  font-size: var(--text-small-print-size);
  font-weight: var(--text-small-print-weight);
  line-height: var(--text-small-print-height);
}

.text-link-m {
  color: currentColor;
  font-size: var(--text-link-m-size);
  font-weight: var(--text-link-m-weight);
  line-height: var(--text-link-m-line-height);
}

.text-link-s {
  color: currentColor;
  font-size: var(--text-link-s-size);
  font-weight: var(--text-link-s-weight);
  line-height: var(--text-link-s-line-height);
}

// Utilities

$font-weights: (
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
);

@each $key, $value in $font-weights {
  .font-#{$key} {
    font-weight: $value;
  }
}

.italic {
  font-style: italic;
}
