.specialties-slider {
  margin-top: 10px;

  &.section-slider {
    margin-left: -25px;
    margin-right: -25px;

    @media screen and (min-width: ($tablet - 1)) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media screen and (min-width: ($tablet - 1px)) {
    .slick-slide > div {
      display: flex;
    }
  }
  

  .desktop-specialties-slider {
    display: none;

    @media screen and (min-width: ($tablet - 1px)) {
      display: block;
    }
  }

  .mobile-specialties-slider {
    @media screen and (min-width: ($tablet - 1px)) {
      display: none;
    }
  }
}