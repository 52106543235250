@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #006747;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #006747;
$color-banner-blue: #1a2857;

$color-success: #2ebb77;
$color-warning: #e34343;
$color-icon: #1a2857;
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.symptomChecker {
  .symptomCard {
    margin: $baseline * 0.5 0 0;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (min-width: $laptop) {
      margin-left: 0;
    }
  }

  .icon {
    background: $color-icon;
  }

  .btnContainer {
    @media screen and (min-width: $tablet) {
      margin-left: $baseline * 2;
      padding-top: $baseline * 0.5;
    }
  }

  &--disabled {
    .symptomCard {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
