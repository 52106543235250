.edit-gp-consultation-page,
.home-page {
  .confirm-modal {
    h2.modal-title {
      display: none;
    }

    .modal-text {
      margin-top: 0;
      max-width: none;
      font-size: 1rem;
      font-weight: 700;
    }

    .text-input-wrapper {
      border: none;
    }

    textarea {
      background-color: $color-white;
      border: 1px solid #dfe2ed;
      border-radius: 0;
      accent-color: #597ebc;

      &:focus {
        outline-color: #597ebc;
      }

      &::placeholder {
        color: #676e78;
      }

      &:disabled {
        display: none;
      }
    }

    .confirm-modal-options-container {
      margin-bottom: 24px;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      padding: 8px;
    }

    .cancel-option {
      margin-bottom: 10px;

      label {
        cursor: pointer;
        font-size: var(--font-size-m);
      }
    }

    .cancel-reason-form {
      h3 {
        font-size: 0.75rem;
      }
    }

    @media screen and (min-width: $tablet) {
      .confirm-modal-options-container {
        display: flex;
        flex-wrap: wrap;
      }

      .cancel-option {
        flex: 1 1 49%;
      }

      .cancel-option:nth-child(odd) {
        margin-right: 10px;
      }

      .confirm-modal-content .modal-text {
        line-height: 26px;
        font-size: 1.25rem;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media screen and (min-width: $laptop) {
      .confirm-modal-content .modal-text {
        width: 400px;
        line-height: 26px;
      }
    }
  }
}
