.change-password-page {
  form {
    max-width: 660px;
    margin: 0 auto;
  }

  .page-content {
    padding-top: 10vh;
  }
}
