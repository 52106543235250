.page.book-consultation-page {
  .page-wrapper {
    padding-top: 96px;
  }

  .patient-btn .paper-content {
    height: 100%;
  }

  textarea {
    font-size: var(--font-size-m);
  }

  .radio-label {
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
  }

  .radio-label,
  .paper {
    flex: 1;
  }

  .radio-label .video-call-icon,
  .radio-label .phone-call-icon {
    margin-right: 10px;
  }

  button.paper.color-paper:not(.selected) {
    border-left: 10px solid #dfe3ed;
  }

  .paper-content {
    padding: 14px 13px;
  }

  .section-title {
    display: flex;
    align-items: center;
  }

  .confirm-details-subtitle {
    margin: 20px 0;
  }

  .validation-errors {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .horizontal-scroll.has-error {
    border-left: none;
    border-right: none;
  }

  .stepper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .step {
    width: 24px;
    height: 24px;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 50%;
    border: 1px solid $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step:not(:last-child) {
    margin-right: 40px;
  }

  .paper.patient-btn {
    min-height: 60px;
    width: 150px;
    flex: none;
  }

  .paper-content.color-paper:before {
    width: 10px;
    min-height: 100%;
    background-color: #dfe3ed;
  }

  .patient-btn:hover {
    cursor: pointer;
  }

  .radio-button-control {
    margin-right: 0;
  }

  .step.current {
    background-color: $color-white;
  }

  .step.current .step-number {
    background-position: center;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-color: $color-header-primary-bg;
  }

  .paper {
    font-family: var(--font-family-primary);
    display: flex;
  }

  .video-link-text-message {
    border-top: 1px solid $color-secondary-bg;
    text-align: left;
  }

  .summary-table {
    h2 {
      font-size: 24px;
    }
  }

  .table-row,
  .table-cell {
    padding: 12px 0px;
    border-top: 1px solid $color-secondary-bg;
    font-size: 14px;
  }

  .table-row th {
    font-weight: 500;
  }

  .table-row th,
  .table-row td {
    padding: 14px 0px;
  }

  .table-row:last-child,
  .table-cell:last-child {
    border-bottom: 1px solid $color-secondary-bg;
  }

  .time-pick {
    width: 130px;
  }

  .time-pick .paper-header {
    font-weight: 400;
  }

  .time-pick:not(:first-child) {
    margin-left: 15px;
  }

  .paper.time-pick .paper-content {
    padding: 10px;
  }

  .gender-pick:not(:last-child) {
    margin-right: 10px;
  }

  .consultation-address .city-post-line {
    flex-direction: column;
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family-primary);
    font-weight: 500;
    padding: 10px 0;
    border-bottom: 1px solid #e1e5ee;
  }

  .cell-value {
    text-align: left;
  }

  .list-item-icon:hover {
    cursor: pointer;
  }

  .action-link {
    font-weight: 500;
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
    font-family: var(--font-family-primary);
  }

  .btn.action-link {
    border: none;
    padding: 0;
  }

  .day-slot {
    display: flex;
    flex: 1;
    width: 150px;
    flex-direction: row-reverse;
    align-items: center;
    background-color: $color-white;
    padding: 10px;
    border-left: 1px solid #f0f2f7;
  }

  .day-slot-header {
    display: flex;
    justify-content: flex-end;
  }

  .day-slot-label {
    text-align: center;
    margin: $baseline * 0.5 0;
    font-size: var(--font-size-m);

    @media screen and (min-width: $tablet) {
      text-align: left;
      margin-left: 10px;
    }
  }

  .day-slot-header,
  .day-slot-label,
  .city-post-line .form-control,
  .summary-table,
  .paper-content {
    width: 100%;
  }

  .form-control {
    margin: $baseline 0 0;

    &.no-label {
      margin-top: math.div($baseline, 2);
    }
  }

  .tooltip-icon-wrapper svg {
    margin-bottom: -3px;
  }

  .book-tooltip-text {
    font-weight: 400;
  }

  .section-hidden {
    display: none;
  }

  .paper-header-title {
    font-weight: 700;
  }

  @media screen and (max-width: $mobile) {
    .gender-pick {
      margin-right: 0;
      margin-top: 10px;
    }
  }

  .preparing-payment {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: $tablet) {
  .book-consultation-page {
    .details-row {
      display: flex;

      .form-control {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 30px;
      }
    }

    .btn-container {
      margin: 0 auto;
      width: 380px;
    }

    .files-list .list-item {
      flex: 0.5;
    }

    .paper-header-title {
      margin-left: 10px;
    }

    .horizontal-scroll .day-slot {
      flex-direction: row;

      .day-slot-header {
        width: auto;
      }
    }

    .day-slot:not(:last-child) {
      margin-right: 10px;
    }

    .details-row .city-post-line {
      flex-direction: row;
    }

    .horizontal-scroll .day-wrapper,
    .consultation-address .city-post-line,
    .paper-header-title {
      width: 100%;
    }

    .paper.time-pick .paper-content {
      padding: 5px 10px;
    }
  }
}
