.page.terms-and-conds-page {
  .page-content {
    @media screen and (min-width: $tablet) {
      text-align: center;
    }
  }

  .btn {
    max-width: 280px;
  }
}
