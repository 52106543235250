.page.create-account-page {
  padding-top: 80px;

  .form-control {
    margin: 16px 0 0;

    // gap for fields without label
    &.no-label {
      margin-top: 4px;
    }
  }

  .section {
    padding: 12px 24px 24px;

    @include tablet {
      padding: 16px 0 32px;
    }

    margin-top: 0;
  }

  .datepicker-root .text-input-wrapper {
    display: flex;
  }

  .city-post-line .form-control {
    width: 100%;
  }

  .section-title {
    color: $text-color-primary-bg;
  }

  .radio-control .paper-content {
    padding: 5px 15px;
  }

  .page-content {
    margin-top: $baseline * 2;
  }

  .client-identifier {
    p {
      font-weight: 400;
      margin-top: 8px;
      font-size: 0.875rem;

      @media screen and (min-width: $tablet) {
        font-size: 1rem;
      }
    }
  }

  input.password-input {
    height: 37px;
  }

  .btn.show-password {
    height: 37px;
  }

  .full-name-wrapper .form-control:first-child {
    margin-right: 10px;
  }

  .create-account-external-link {
    color: $text-color-primary-bg;
  }
}

@media screen and (min-width: $tablet) {
  .create-account-page {
    .page-content {
      margin: 0 auto;
      width: 672px;
    }
  }
}

@media screen and (min-width: 640px) {
  .create-account-page {
    .details-row {
      display: flex;
    }

    .dob-wrapper {
      margin-left: 10px;
    }

    .details-row {
      .form-control {
        flex: 1;
      }

      .form-control:not(:first-child) {
        margin-left: 20px;
      }
    }

    .btn-container {
      margin: 0 auto;
      width: 450px;
    }
  }
}
