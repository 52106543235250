.page-footer {
  height: 62px;
  color: $text-color-primary-bg;
  background: $color-white;
}

.bottom-sticky-footer-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.page-footer-nav-item {
  text-decoration: none;
  color: $text-color-primary-bg;
}

.page-footer-content {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.pager-footer-content-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pager-footer-content-title {
  font-size: 10px;
}

@media screen and (min-width: 1024px) {
  .bottom-sticky-footer-wrapper {
    display: none;
  }
}
